<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-label class="fs-4 fw-bold">{{ $t('orderB2b.orders') }}</ion-label>
            </ion-col>
            <ion-col size="3" class="header-right">
              <ion-row class="justify-end align-center">
                <ion-icon class="icon-item" :icon="filterOutline" @click="openFilterModal()" />
                <notification-badge />
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true">
      <span v-if="isLoadingRef">
        <skeleton-order-item v-for="(item, index) in 5" :key="index" />
      </span>
      <OrderItem
        v-else
        ref="orderItemComponent"
        :orders="orderList"
        :isEnableSoftBlockOrder="isEnableSoftBlockOrder"
        @reloadOrder="reloadOrder"
      />
      <ion-infinite-scroll
        @ionInfinite="loadMore($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="false"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
    <ion-modal mode="md" :is-open="isOpenFilterRef" @didDismiss="setOpenFilterModal(false)">
      <FilterModal
        :isEnableSoftBlockOrder="isEnableSoftBlockOrder"
        :orderStatusesData="orderStatuses"
        :filterOptions="filterOptions"
        :fakeOnHoldStatus="FAKE_ON_HOLD_STATUS_ID"
        @close-page="setOpenFilterModal(false)"
        @apply-filter="applyFilter"
      />
    </ion-modal>
  </ion-page>
</template>
<script>
import { getIsEnableSoftBlockOrder, getOrderStatuses } from '@/services/shared/graphql';
import { onIonViewDidEnter, toastController } from '@ionic/vue';
import { alertCircleOutline, filterOutline, notificationsOutline } from 'ionicons/icons';
import { defineComponent, inject, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import { getPurchaseB2bOrders, getTotalCountPurchaseB2bOrder } from '@/modules/b2b/services/graphql';
import { saleGetOrders, saleGetTotalCountOrder } from '@/modules/sale/services/graphql';

import NotificationBadge from '@/modules/shared/components/notifications/NotificationBadge.vue';
import { TL_TASK_STATUSES_ID } from '@/modules/shared/constants';
import { executeApolloClient } from '@/services/shared/apollo-client';
import FilterModal from './components/FilterModal.vue';
import OrderItem from './components/OrderItem.vue';
import SkeletonOrderItem from './components/OrderItemSkeleton.vue';
export default defineComponent({
  name: 'OrderList',
  components: {
    OrderItem,
    NotificationBadge,
    SkeletonOrderItem,
    FilterModal
  },
  setup() {
    const storage = inject('$storage');
    const router = useRouter();

    const isMerchantUser = router.currentRoute.value.fullPath.indexOf('/b2b/order') > -1;

    // declare ref variable
    const orderItemComponent = ref(null);
    const isLoadingRef = ref(true);
    const isOpenFilterRef = ref(false);
    const setOpenFilterModal = (state) => (isOpenFilterRef.value = state);
    const orderList = ref([]);
    const totalCount = ref(0);
    const isDisableLoadMore = ref(false);
    const isFirstLoad = ref(true);
    const isEnableSoftBlockOrder = ref(false);
    const orderStatuses = ref([]);
    const FAKE_ON_HOLD_STATUS_ID = 9999;

    const openToast = async (message, color = 'danger', position = 'top') => {
      const toast = await toastController.create({
        message,
        position,
        color,
        duration: 3000,
        icon: alertCircleOutline
      });
      return toast.present();
    };

    const initFilterOption = {
      customerBuyerNames: [],
      fulfillmentStatusNames: [],
      orderStatusNames: [],
      startDeliveryDate: null,
      endDeliveryDate: null,
      statusIds: [],
      tlTaskStatusIds: [],
      customerBuyerIds: [],
      isLoadMore: false,
      limit: 5,
      blockStatus: [],
      offset: 0,
      orderNumber: null
    };
    const filterOptions = reactive({ ...initFilterOption });

    const resetFilterOrder = () => {
      Object.assign(filterOptions, initFilterOption);
      orderList.value = [];
      totalCount.value = 0;
    };

    const getTlTaskStatusIdByCategory = (ids) => {
      const category = {
        1: [TL_TASK_STATUSES_ID.ARRIVED_OR_IN_PROGRESS, TL_TASK_STATUSES_ID.STARTED], // DELIVERY_STARTED
        2: [TL_TASK_STATUSES_ID.COMPLETED], // DELIVERY_COMPLETED
        3: [TL_TASK_STATUSES_ID.FAILED] // DELIVERY_FAILED
      };

      // example of ids [1,3] then should return category."1" and category."2"
      const tlTaskStatusIds = ids.flatMap((id) => category[id] || []);
      return tlTaskStatusIds;
    };

    // get order data
    const getOrderList = async () => {
      try {
        isFirstLoad.value = false;
        if (!filterOptions.tenantId) {
          const user = await storage.getUser();
          filterOptions.tenantId = user.tenant.id;
        }
        if (filterOptions.offset === 0) isLoadingRef.value = true;

        // clone filter
        const statusIds = filterOptions.statusIds.map((obj) => obj);
        // replace fake on hold status to processing
        if (statusIds.includes(FAKE_ON_HOLD_STATUS_ID)) {
          statusIds.splice(statusIds.indexOf(FAKE_ON_HOLD_STATUS_ID), 1);
          statusIds.push(filterOptions.processingStatusId);
        }

        const filterOptionsParams = Object.assign({}, { ...filterOptions });
        filterOptionsParams.statusIds = statusIds;

        // convert tlTaskStatusIds to id table tl_task_statuses
        filterOptionsParams.tlTaskStatusIds = getTlTaskStatusIdByCategory(
          filterOptionsParams.tlTaskStatusIds || []
        );

        filterOptionsParams.orderId = filterOptionsParams?.orderNumber
          ? Number(filterOptionsParams.orderNumber)
          : null;

        if (isMerchantUser) {
          const res = await executeApolloClient(getPurchaseB2bOrders, filterOptionsParams);

          // checking to combine or reset result
          if (filterOptions.offset === 0) {
            orderList.value = res.getPurchaseB2bOrders.data;
          } else {
            orderList.value = [...orderList.value, ...res.getPurchaseB2bOrders.data];
          }

          // load total count if it don't have value yet
          if (totalCount.value === 0) {
            const totalCountRes = await executeApolloClient(
              getTotalCountPurchaseB2bOrder,
              filterOptionsParams
            );
            totalCount.value = totalCountRes.getTotalCountPurchaseB2bOrder;
          }
        } else {
          const res = await executeApolloClient(saleGetOrders, filterOptionsParams);

          // checking to combine or reset result
          if (filterOptions.offset === 0) {
            orderList.value = res.saleGetOrders.data;
          } else {
            orderList.value = [...orderList.value, ...res.saleGetOrders.data];
          }

          // load total count if it don't have value yet
          if (totalCount.value === 0) {
            const totalCountRes = await executeApolloClient(saleGetTotalCountOrder, filterOptionsParams);
            totalCount.value = totalCountRes.saleGetTotalCountOrder;
          }
        }

        // checking to display load more animation
        if (totalCount.value === orderList.value.length) {
          isDisableLoadMore.value = true;
        }

        if (filterOptions.offset === 0) isLoadingRef.value = false;
      } catch (e) {
        await openToast(e.message);
      }
    };

    // check enable soft block order
    const checkEnableSoftBlockOrder = async () => {
      const user = await storage.getUser();
      const selectedCompany = await storage.getSelectedCompany();
      try {
        const res = await executeApolloClient(getIsEnableSoftBlockOrder, {
          tenantId: user.tenant.id,
          customerId: selectedCompany.id
        });
        isEnableSoftBlockOrder.value = res.getIsEnableSoftBlockOrder;
      } catch (e) {
        await openToast(e.message);
      }
    };

    // get order status list
    const execGetOrderStatuses = async () => {
      try {
        const res = await executeApolloClient(getOrderStatuses);
        orderStatuses.value = res.getOrderStatuses;
      } catch (e) {
        await openToast(e.message);
      }
    };

    const loadMore = async (e) => {
      if (!isLoadingRef.value && orderList.value.length) {
        filterOptions.offset = orderList.value.length;
        await getOrderList();
      }
      e.target.complete();
    };

    const applyFilter = async (filterOptionData) => {
      Object.assign(filterOptions, filterOptionData);
      filterOptions.offset = 0; // reset offset
      orderList.value = [];
      totalCount.value = 0;

      // close filter modal
      setOpenFilterModal(false);

      // perform get data
      await getOrderList();
    };

    const openFilterModal = async () => {
      await execGetOrderStatuses();
      setOpenFilterModal(true);
    };

    const reloadOrder = async () => {
      // reset filter options
      resetFilterOrder();

      // perform get data
      await getOrderList();
    };

    onIonViewDidEnter(async () => {
      // get previous url
      const forwardUrl = router.options.history.state.forward;
      // should check is enable soft block anytime user open this page
      await checkEnableSoftBlockOrder();

      // reset filter and order list everytime the page is reached from another menu or never loaded before yet
      if (!forwardUrl || forwardUrl.indexOf('/order') === -1 || isFirstLoad.value) {
        // reset filter options
        resetFilterOrder();

        // perform get data
        await getOrderList();
      }
    });
    return {
      orderItemComponent,
      filterOutline,
      notificationsOutline,
      isLoadingRef,
      router,
      filterOptions,
      resetFilterOrder,
      orderList,
      loadMore,
      isDisableLoadMore,
      isOpenFilterRef,
      setOpenFilterModal,
      isEnableSoftBlockOrder,
      openFilterModal,
      orderStatuses,
      applyFilter,
      reloadOrder,
      FAKE_ON_HOLD_STATUS_ID
    };
  }
});
</script>
<style lang="scss">
.btn-icon {
  --padding-start: 0px;
  --padding-end: 0px;
}

.my-popover {
  --max-width: 120px;
  --backdrop-opacity: 0;
}

.my-popover .popover-content {
  margin-top: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

ion-segment {
  width: 100%;
  margin-top: 1rem;
  border-bottom: 1px solid #f5f5f5;
  ion-label {
    text-transform: capitalize;
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
}
.text-bold {
  font-weight: bold;
  color: #000;
}

ion-modal.select-company {
  --width: 90%;
  --height: 48%;
  .sc-ion-modal-ios {
    border-radius: 4px;
  }
}
#row {
  height: 50px;
}
.icon-item {
  font-size: 24px;
  padding-right: 10px;
}
@media only screen and (max-width: 360px) {
  .icon-item {
    font-size: 20px;
    padding-right: 4px;
  }
}
@media only screen and (max-width: 280px) {
  .btn-notify {
    width: 22px;
  }
}
.skeleton-child {
  --border-radius: 8px;
}
</style>
